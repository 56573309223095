import React, { useState } from "react"
import * as Mui from "@mui/material"
import {
  addUser,
  editUser,
  updatePassword,
} from "../../containers/Login/userSlice"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../app/store"
import { Chip } from "@mui/material"

interface UserProp {
  firstName: string
  lastName: string
  emailAddress: string
  phoneNumber: string
  maxDailyLeadClaim: string
  smsProviderId: string
  isSuperAdmin: boolean
  requireSMS: boolean
  requireIPRestriction: boolean
  allowedIPs: string[]
  canViewWebsiteLeads: boolean
  canReclaim: boolean
}

const AddUserForm = (props: any) => {
  const dispatch = useDispatch<AppDispatch>()
  const [user, setUser] = useState<UserProp>(props.user)
  const [loading, setLoading] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  )
  const [ipInput, setIpInput] = useState("")
  const [newPassword, setNewPassword] = useState("")

  const handleInputChange = (event: { target: { id: any; value: any } }) => {
    const { id, value } = event.target
    setUser({ ...user, [id]: value })
  }

  const handleSave = async () => {
    setLoading(true)
    try {
      if (props.user.id) {
        const updatedUser = await dispatch(editUser(user)).unwrap()
        setSnackbarMessage("User updated successfully!")
        setSnackbarSeverity("success")
        props.onUserSaved(updatedUser)
      } else {
        const newUser = await dispatch(addUser(user)).unwrap()
        setSnackbarMessage("User added successfully!")
        setSnackbarSeverity("success")
        props.onUserSaved(newUser)
      }
    } catch (error) {
      setSnackbarMessage("Failed to save user!")
      setSnackbarSeverity("error")
    } finally {
      setLoading(false)
      setOpenSnackbar(true)
    }
  }

  const handlePasswordUpdate = async () => {
    if (!newPassword) {
      setSnackbarMessage("Please enter a new password!")
      setSnackbarSeverity("error")
      setOpenSnackbar(true)
      return
    }

    setLoading(true)
    try {
      await dispatch(
        updatePassword({ id: props.user.id, password: newPassword })
      ).unwrap()
      setSnackbarMessage("Password updated successfully!")
      setSnackbarSeverity("success")
      setNewPassword("") // Şifre güncellendikten sonra alanı temizle
    } catch (error) {
      setSnackbarMessage("Failed to update password!")
      setSnackbarSeverity("error")
    } finally {
      setLoading(false)
      setOpenSnackbar(true)
    }
  }

  const handleSelectbox = (event: any) => {
    setUser({ ...user, [event.target.name]: event.target.value })
  }

  const handleToggleSMS = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, requireSMS: event.target.checked })
  }

  const handleToggleIPRestriction = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUser({ ...user, requireIPRestriction: event.target.checked })
  }

  const handleToggleWebsiteLeads = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUser({ ...user, canViewWebsiteLeads: event.target.checked })
  }

  const handleToggleReclaim = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, canReclaim: event.target.checked })
  }

  const handleIpAdd = () => {
    const updatedAllowedIPs = user.allowedIPs || []

    if (ipInput && !updatedAllowedIPs.includes(ipInput)) {
      setUser({ ...user, allowedIPs: [...updatedAllowedIPs, ipInput] })
      setIpInput("")
    }
  }

  const handleDeleteIp = (ip: string) => {
    setUser({
      ...user,
      allowedIPs: user.allowedIPs.filter((item) => item !== ip),
    })
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  return (
    <form>
      <Mui.DialogContent>
        <Mui.Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <Mui.TextField
              id="firstName"
              label="First Name"
              defaultValue={user.firstName}
              onChange={handleInputChange}
            />
            <Mui.TextField
              id="lastName"
              label="Last Name"
              defaultValue={user.lastName}
              onChange={handleInputChange}
            />
            <Mui.TextField
              id="emailAddress"
              label="Email Address"
              defaultValue={user.emailAddress}
              onChange={handleInputChange}
            />
            <Mui.TextField
              id="phoneNumber"
              label="Phone Number"
              defaultValue={user.phoneNumber}
              onChange={handleInputChange}
            />
            <Mui.TextField
              id="maxDailyLeadClaim"
              label="Max Daily Lead Claim"
              defaultValue={user.maxDailyLeadClaim}
              onChange={handleInputChange}
            />
            <Mui.TextField
              id="smsProviderId"
              label="Sms Provider Id"
              defaultValue={user.smsProviderId}
              onChange={handleInputChange}
            />
            <Mui.FormControl sx={{ m: 1, minWidth: 224 }}>
              <Mui.InputLabel id="simple-select-helper-label">
                Admin User
              </Mui.InputLabel>
              <Mui.Select
                labelId="simple-select-helper-label"
                name="isSuperAdmin"
                defaultValue={user.isSuperAdmin}
                label="isSuperAdmin"
                onChange={handleSelectbox}
              >
                <Mui.MenuItem value={"true"}>True</Mui.MenuItem>
                <Mui.MenuItem value={"false"}>False</Mui.MenuItem>
              </Mui.Select>
            </Mui.FormControl>
            <Mui.FormControl>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Mui.TextField
                  type="password"
                  label="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <Mui.Button
                  variant="contained"
                  color="secondary"
                  onClick={handlePasswordUpdate}
                  disabled={loading}
                >
                  {loading ? "Updating..." : "Update Password"}
                </Mui.Button>
              </div>
            </Mui.FormControl>
            <Mui.FormControlLabel
              style={{ width: "100%" }}
              control={
                <Mui.Switch
                  checked={user.requireSMS}
                  onChange={handleToggleSMS}
                  name="requireSMS"
                />
              }
              label="Require SMS Verification"
            />
            <Mui.FormControlLabel
              style={{ width: "100%" }}
              control={
                <Mui.Switch
                  checked={user.canViewWebsiteLeads}
                  onChange={handleToggleWebsiteLeads}
                  name="canViewWebsiteLeads"
                />
              }
              label="Can View Website Leads"
            />
            <Mui.FormControlLabel
              style={{ width: "100%" }}
              control={
                <Mui.Switch
                  checked={user.canReclaim}
                  onChange={handleToggleReclaim}
                  name="canReclaim"
                />
              }
              label="Can Reclaim"
            />
            <Mui.FormControlLabel
              style={{ width: "100%" }}
              control={
                <Mui.Switch
                  checked={user.requireIPRestriction}
                  onChange={handleToggleIPRestriction}
                  name="requireIPRestriction"
                />
              }
              label="IP Restriction"
            />
            {user.requireIPRestriction && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Mui.TextField
                    label="Add IP Address"
                    value={ipInput}
                    size="small"
                    onChange={(e) => setIpInput(e.target.value)}
                  />
                  <Mui.Button
                    variant="contained"
                    color="primary"
                    onClick={handleIpAdd}
                  >
                    Add IP
                  </Mui.Button>
                </div>
                <div>
                  {user?.allowedIPs?.map((ip) => (
                    <Chip
                      key={ip}
                      label={ip}
                      onDelete={() => handleDeleteIp(ip)}
                      style={{ margin: "5px" }}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
          <Mui.Button
            variant="contained"
            type="button"
            onClick={handleSave}
            disabled={loading}
          >
            {loading ? "Saving..." : "Save"}
          </Mui.Button>
        </Mui.Box>
      </Mui.DialogContent>

      <Mui.Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Mui.Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Mui.Alert>
      </Mui.Snackbar>
    </form>
  )
}

export default AddUserForm
