import { store } from "../../app/store"
import * as Mui from "@mui/material"
import { SubmitHandler, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { LoginForm } from "../../types"
import { createTheme } from "@mui/material/styles"
import { userLogin, resetState, verifyOTP, userLogout } from "./userSlice" // userLogout eylemi eklendi
import { RootState } from "../../app/store"
import Notification from "../../components/Notification"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import logo from "../../assets/images/logo.png"
import "./index.scss"

type AppDispatch = typeof store.dispatch
const theme = createTheme()

export default function Login() {
  const { register, handleSubmit } = useForm<LoginForm>()
  const dispatch = useDispatch<AppDispatch>()
  const { status, error, auth, userInfo, verifySms } = useSelector(
    (state: RootState) => state.user
  )
  const [openOTPDialog, setOpenOTPDialog] = useState(false)
  const [otpCode, setOtpCode] = useState("") // OTP kodunu saklamak için
  const navigate = useNavigate()

  useEffect(() => {
    if (userInfo?.requireSMS) {
      setOpenOTPDialog(true)
    }
  }, [userInfo])
  useEffect(() => {
    if (verifySms) {
      localStorage.setItem("requireSMS", "false")
      navigate("/")
    }
  }, [navigate, verifySms])

  // Sayfa yenilendiğinde veya giriş kontrolü
  useEffect(() => {
    const storedAuth = localStorage.getItem("auth")
    const storedRequireSMS = localStorage.getItem("requireSMS")

    if (storedAuth && storedRequireSMS === "true") {
      // Eğer auth var ama requireSMS gerekiyorsa, OTP penceresi göster
      setOpenOTPDialog(true)
    } else if (storedAuth && storedRequireSMS === "false") {
      // Eğer requireSMS yoksa doğrudan ana sayfaya yönlendirme
      navigate("/")
    }
  }, [navigate])

  const submitForm: SubmitHandler<LoginForm> = (data: LoginForm) => {
    if (status !== "loading") {
      dispatch(userLogin(data))
    }
  }

  const handleOTPSubmit = () => {
    dispatch(verifyOTP(otpCode))
  }

  // Cancel butonuna basıldığında logout işlemi
  const handleCancelOTP = () => {
    setOpenOTPDialog(false)
    dispatch(userLogout()) // Kullanıcı oturumunu kapat
    navigate("/login") // Kullanıcıyı login sayfasına yönlendir
  }

  useEffect(() => {
    if (auth) {
      const storedRequireSMS = localStorage.getItem("requireSMS")
      if (storedRequireSMS === "true") {
        // Eğer SMS doğrulama gerekiyorsa OTP dialog göster
        setOpenOTPDialog(true)
      } else {
        // SMS doğrulama gerekmezse giriş başarılı
        navigate("/")
      }
    }
  }, [auth, navigate])

  return (
    <>
      {status === "failed" && (
        <Notification
          error={error}
          onClick={() => {
            dispatch(resetState())
          }}
        />
      )}
      <Mui.ThemeProvider theme={theme}>
        <div className="coverimage" />
        <Mui.Container className="bg loginform" component="main" maxWidth="xs">
          <Mui.CssBaseline />
          <Mui.Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Mui.Box>
              <img className="logo" src={logo} alt="safemile" width={220} />
            </Mui.Box>
            <Mui.Box
              component="form"
              onSubmit={handleSubmit(submitForm)}
              sx={{ mt: 1 }}
            >
              <Mui.TextField
                margin="normal"
                required
                fullWidth
                label="Email Address"
                autoComplete="email"
                autoFocus
                {...register("emailAddress")}
              />
              <Mui.TextField
                margin="normal"
                required
                fullWidth
                label="Password"
                type="password"
                autoComplete="current-password"
                {...register("password")}
              />
              <Mui.FormControlLabel
                control={<Mui.Checkbox color="primary" />}
                label="Remember me"
                {...register("rememberMe")}
              />
              <Mui.Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Mui.Button>
            </Mui.Box>
          </Mui.Box>

          {/* OTP Dialog */}
          <Mui.Dialog open={openOTPDialog} onClose={handleCancelOTP}>
            {" "}
            {/* Cancel butonuna basılınca çıkış yapılacak */}
            <Mui.DialogTitle>OTP Verification</Mui.DialogTitle>
            <Mui.DialogContent>
              <Mui.TextField
                autoFocus
                margin="dense"
                label="Enter OTP"
                fullWidth
                variant="standard"
                value={otpCode}
                onChange={(e) => setOtpCode(e.target.value)}
              />
            </Mui.DialogContent>
            <Mui.DialogActions>
              <Mui.Button onClick={handleOTPSubmit} color="primary">
                Verify
              </Mui.Button>
              <Mui.Button onClick={handleCancelOTP} color="secondary">
                {" "}
                {/* Cancel butonuna basınca logout yapılacak */}
                Cancel
              </Mui.Button>
            </Mui.DialogActions>
          </Mui.Dialog>
        </Mui.Container>
      </Mui.ThemeProvider>
    </>
  )
}
