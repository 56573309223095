import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { LoginForm, User } from "../../types"

interface State {
  userInfo: User | null
  userList: User[] | null
  status: string
  error: string
  auth: boolean
  requireSMS: boolean
  verifySms: boolean
}

const initialState: State = {
  userInfo: null,
  userList: null,
  status: "idle",
  error: "",
  auth: localStorage.getItem("auth") ? true : false,
  requireSMS: false,
  verifySms: false,
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = "idle"
    },
  },
  extraReducers(builder) {
    builder
      .addCase(userLogin.pending, (state) => {
        state.status = "loading"
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.userInfo = action.payload
        state.auth = !action.payload.requireSMS
        state.requireSMS = action.payload.requireSMS
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.status = "failed"
        state.error = (action.payload as string) || ""
        state.auth = false
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.userInfo = action.payload
      })
      .addCase(getUser.rejected, (state) => {
        state.auth = false
        state.userInfo = null
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.userList = action.payload
      })
      .addCase(getUserList.rejected, (state) => {
        state.userList = null
      })
      .addCase(userLogout.fulfilled, (state) => {
        state.userInfo = null
        state.auth = false
      })
      //TODO ask
      .addCase(deleteUser.fulfilled, (state) => {
        state.userList = null
      })
      .addCase(verifyOTP.pending, (state) => {
        state.status = "loading"
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.auth = true
        state.userInfo = action.payload
        state.verifySms = true
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.status = "failed"
        state.error = (action.payload as string) || ""
      })
  },
})

export const { resetState } = userSlice.actions
/* TODO check */
export const selectAllUsers = (state: any) => state.user.userList
export const userLogin = createAsyncThunk(
  "user/login",
  async (credentials: LoginForm, { rejectWithValue }) => {
    try {
      const { emailAddress, password, rememberMe } = credentials
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/account/login`,
        { emailAddress, password, rememberMe },
        { withCredentials: true }
      )
      localStorage.setItem("auth", "true")
      localStorage.setItem("requireSMS", data.requireSMS ? "true" : "false")
      return data
    } catch (error: any) {
      if (error.response) {
        return rejectWithValue(error.response.data)
      }
    }
  }
)

export const userLogout = createAsyncThunk("user/logout", async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/account/logout`,
      {
        withCredentials: true,
      }
    )
    localStorage.removeItem("auth")
    return data
  } catch (error: any) {
    console.error(error)
  }
})

export const userActivity = createAsyncThunk("user/activity", async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/account/activity`,
      {
        withCredentials: true,
      }
    )
    return data
  } catch (error: any) {
    console.error(error)
  }
})

export const getUser = createAsyncThunk("user/info", async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/account/user`,
      {
        withCredentials: true,
      }
    )
    return data
  } catch (error: any) {
    localStorage.removeItem("auth")
    throw error
  }
})

export const getUserList = createAsyncThunk(
  "users/info",
  async (params: { includeLastHistory?: boolean } = {}) => {
    const query = new URLSearchParams(
      Object.entries({
        ...params,
        includeLastHistory: params.includeLastHistory ? "true" : "false", // boolean'ı string'e çeviriyoruz
      })
    ).toString()

    const url = `${process.env.REACT_APP_API_URL}/account/users${
      query ? `?${query}` : ""
    }`

    try {
      const response = await axios.get(url, {
        withCredentials: true,
      })
      return response.data
    } catch (err) {
      console.error(err)
      throw err
    }
  }
)

export const addUser = createAsyncThunk(
  "users/addUser",
  async (data: Record<string, any>) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/account/signup`,
        data,
        {
          withCredentials: true,
        }
      )
      return response.data
    } catch (err: any) {
      // TODO: error handling
      throw err.response.data.message
    }
  }
)

export const updatePassword = createAsyncThunk(
  "users/updatePassword", // Thunk adı
  async (data: Record<string, any>) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/account/update-password`,
        data,
        {
          withCredentials: true,
        }
      )
      return response.data
    } catch (err: any) {
      throw err.response.data.message
    }
  }
)

export const editUser = createAsyncThunk(
  "users/editUser",
  async (data: Record<string, any>) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/account/${data.id}`,
        data,
        {
          withCredentials: true,
        }
      )
      return response.data
    } catch (err: any) {
      // TODO: error handling
      throw err.response.data.message
    }
  }
)

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (data: Record<string, any>) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/account/${data.id}`,
        data
      )
      //TODO make better ask
      return response.data
    } catch (err: any) {
      // TODO: error handling
      throw err.response.data.message
    }
  }
)

export const verifyOTP = createAsyncThunk(
  "user/verifyOTP",
  async (otp: string, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/account/verify-otp`,
        { otp },
        {
          withCredentials: true,
        }
      )
      return response.data
    } catch (err: any) {
      if (err.response) {
        return rejectWithValue(err.response.data.message)
      }
    }
  }
)

export default userSlice.reducer
